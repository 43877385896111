// Generated by Framer (e2fa450)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
import * as sharedStyle from "../css/Ek0bMgsON";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {QjwhziJ8e: {hover: true}};

const serializationHash = "framer-kJdLK"

const variantClassNames = {QjwhziJ8e: "framer-v-6a648b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 700, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, id, link, title, width, ...props}) => { return {...props, B5iQVVtP8: link ?? props.B5iQVVtP8, enEmw8DC3: title ?? props.enEmw8DC3 ?? "Button", H8r2BJ18e: icon ?? props.H8r2BJ18e ?? true} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;icon?: boolean;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, enEmw8DC3, H8r2BJ18e, B5iQVVtP8, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "QjwhziJ8e", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={B5iQVVtP8} openInNewTab smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-6a648b", className, classNames)} framer-1ty5brw`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"QjwhziJ8e"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-235ff5aa-941f-4894-b533-1404a77ee3e7, rgb(255, 255, 255))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} {...addPropertyOverrides({"QjwhziJ8e-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1wmn692"} data-styles-preset={"Ek0bMgsON"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-ea4213d2-e93b-447e-95da-37f6ad17f488, rgb(40, 117, 250)))"}}>Button</motion.p></React.Fragment>} className={"framer-1pw4wxa"} data-framer-name={"Try the demo"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"mMSjlbYYO"} style={{"--extracted-r6o4lv": "var(--token-ea4213d2-e93b-447e-95da-37f6ad17f488, rgb(40, 117, 250))", "--framer-paragraph-spacing": "0px"}} text={enEmw8DC3} verticalAlignment={"top"} withExternalLayout/>{H8r2BJ18e && (<ComponentViewportProvider ><motion.div className={"framer-7b1zci-container"} layoutDependency={layoutDependency} layoutId={"f6S5ZPWXY-container"} style={{opacity: 1}} variants={{"QjwhziJ8e-hover": {opacity: 0}}}><Phosphor color={"var(--token-ea4213d2-e93b-447e-95da-37f6ad17f488, rgb(40, 117, 250))"} height={"100%"} iconSearch={"arrow"} iconSelection={"ArrowRight"} id={"f6S5ZPWXY"} layoutId={"f6S5ZPWXY"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></ComponentViewportProvider>)}</motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kJdLK.framer-1ty5brw, .framer-kJdLK .framer-1ty5brw { display: block; }", ".framer-kJdLK.framer-6a648b { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: 48px; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: 148px; }", ".framer-kJdLK .framer-1pw4wxa { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-kJdLK .framer-7b1zci-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-kJdLK.framer-6a648b { gap: 0px; } .framer-kJdLK.framer-6a648b > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-kJdLK.framer-6a648b > :first-child { margin-left: 0px; } .framer-kJdLK.framer-6a648b > :last-child { margin-right: 0px; } }", ".framer-kJdLK.framer-v-6a648b.hover .framer-7b1zci-container { left: calc(93.24324324324327% - 20px / 2); position: absolute; top: calc(50.00000000000002% - 20px / 2); z-index: 1; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 148
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"AkOQNejp4":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"enEmw8DC3":"title","H8r2BJ18e":"icon","B5iQVVtP8":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerF7ivyQiOs: React.ComponentType<Props> = withCSS(Component, css, "framer-kJdLK") as typeof Component;
export default FramerF7ivyQiOs;

FramerF7ivyQiOs.displayName = "Button white";

FramerF7ivyQiOs.defaultProps = {height: 48, width: 148};

addPropertyControls(FramerF7ivyQiOs, {enEmw8DC3: {defaultValue: "Button", displayTextArea: false, title: "Title", type: ControlType.String}, H8r2BJ18e: {defaultValue: true, title: "Icon", type: ControlType.Boolean}, B5iQVVtP8: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerF7ivyQiOs, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...PhosphorFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})